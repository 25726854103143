import React from "react"
import Layout from "../sparklePage/layout/Layout"
import Seo from "../core/Seo"

const NotFoundPage = () => {
  const browser = typeof window !== "undefined" && window
  if (!browser) return null
  return (
    <Layout>
      <Seo title="404: Not found" includeZohoTracking />
      <h1>NOT FOUND</h1>
      <p>Sorry, this page does not exist.</p>
    </Layout>
  )
}

export default NotFoundPage
